<template>

<b-sidebar
  visible
  noCloseOnRouteChange
  noHeader
  :width="computedWidth" 
>
  <b-link href="/" class="padding">
    <b-img
      :src="$store.getters.logoURL"
      alt="Responsive image"
      height="53px"
      center
      rounded
      :class="computedClassLogo"
    />
  </b-link>

  <b-nav pills vertical>
    <b-nav-item v-for="(link, index) in availableLinks"
      :key="index"
      link-classes="custom-text"
      :href="link.href"
      :active="$route.path.includes(link.name.toLowerCase())"
    >
      <b-icon :icon="link.icon" :class="computedClassIcon "/><span :class="computedClassSpan">{{ link.name }}</span>
    </b-nav-item>
  </b-nav>

</b-sidebar>

</template>
<script>

export default {
  data(){
    return {
      page: null,
      isClosed: false,
      links: [
      {
        "icon": "house-door",
        "href": "#/",
        "name": "Home",
        "restricted": false
      }, {
        "icon": "calendar3",
        "href": "#/timesheet",
        "name": "Timesheet",
        "restricted": false
      }, {
        "icon": "people",
        "href": "#/users",
        "name": "Users",
        "restricted": true
      }, {
        "icon": "book",
        "href": "#/projects",
        "name": "Projects",
        "restricted": true
      }, {
        "icon": "briefcase",
        "href": "#/clients",
        "name": "Clients",
        "restricted": true
      }, {
        "icon": "receipt",
        "href": "#/invoices",
        "name": "Invoices",
        "restricted": true
      }, {
        "icon": "file-earmark-spreadsheet",
        "href": "#/quotes",
        "name": "Quotes",
        "restricted": true
      }, {
        "icon": "gem",
        "href": "#/reports",
        "name": "Reports",
        "restricted": true
      }, {
        "icon": "file-binary",
        "href": "#/logs",
        "name": "Logs",
        "restricted": true
      }, {
        "icon": "cash",
        "href": "#/taxes",
        "name": "Taxes",
        "restricted": true
      }, {
        "icon": "gear",
        "href": "#/settings",
        "name": "Settings",
        "restricted": true
      }]
    }
  },
  computed : {
    availableLinks: function() {
      return this.links.filter(link => !link.restricted || this.isAdmin)
    },
    isAdmin: function() {
        return this.$store.getters.userRole == 'admin'
    },
    computedWidth: function(){
      return this.isClosed ? "60px" : "250px"
    },
    computedClassSpan: function(){
      return this.isClosed ? "textOpen textClose" : "textOpen"
    },
    computedClassIcon: function(){
      return this.isClosed ? "mr-0 ml-1" : "mr-3 ml-1"
    },
    computedClassLogo: function(){
      return this.isClosed ? "logo logoClosed" : "logo"
    }
  },
  mounted(){
    this.$root.$on('sidebarToggle', () => {
      this.isClosed = !this.isClosed
    })
  }
}
</script>
<style scoped>
.logo{
  margin-top: 15px;
  margin-bottom: 15px;
}
.logoClosed{
  padding: 0;
  width: 55px; 
  height: 55px; 
  object-fit: cover; 
  object-position: -1% 0;
}
.custom-text {
  color: #4d4d4d;
}
.custom-text.active {
  color: #fff;
}
.textClose{
  display: none !important;
}
</style>