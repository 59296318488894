import { INTERNAL_SERVER_ERROR } from '@/api/errors.api'

const crudMixin = {
    data() {
        return {
            error: null,
            errors: {}
        }
    },
    methods: {
        saveObject: function(apiCall, callback, showBanner=true) {
            this.error = null
            this.errors = {}
            this.call(
                apiCall,
                (response) => {
                    if('status_code' in response.data && response.data['status_code'] === 422) {
                        // Fail
                        if('error' in response.data) {
                            this.error = response.data.error
                        }
                        if('errors' in response.data) {
                            this.errors = response.data.errors
                            if('_schema' in this.errors) {
                                if(this.error == null) {
                                    this.error = this.errors['_schema']
                                } else {
                                    this.error += this.errors['_schema']
                                }
                            }
                        }
                        if(this.error) {
                            this.$awn.alert(INTERNAL_SERVER_ERROR);
                        }
                    } else {
                        if(showBanner) {
                            this.$awn.success('Save successful');
                        }
                        if(callback) {
                            callback(response)
                        } else {
                            // This is very brittle
                            this.$router.push(response.data.location.substring(4))
                        }
                    }
                }
            )
        },
        deleteObject: function(apiCall, callback)  {
            this.call(
                apiCall,
                (response) => {
                    this.$awn.success('Delete successful');
                    if(callback) {
                        callback(response)
                    }
                }
            )
        },
        call: function(apiCall, successCallback, failureCallback) {
            apiCall
            .then(response => {
                if(successCallback) {
                    successCallback(response)
                }
            }).catch(error => {
                if(failureCallback) {
                    failureCallback(error)
                } else {
                    this.$awn.alert(error);
                }
            })
        },
    }
}

export default crudMixin
