import httpClient from './httpClient';

const END_POINT = '/settings';
const BANK_ACCOUNTS = '/bankaccounts'

const updateSettings = (settings) => httpClient.post(END_POINT, settings);
const getSetting = (setting) => httpClient.get(END_POINT + '/' + setting);
const getSettings = () => httpClient.get(END_POINT);

const getBankAccounts = () => httpClient.get(END_POINT + BANK_ACCOUNTS)
const getBankAccount = (id) => httpClient.get(END_POINT + BANK_ACCOUNTS + '/' + id)
const deleteBankAccount = (id) => httpClient.delete(END_POINT + BANK_ACCOUNTS + '/' + id);
const createBankAccount = (bank_account) => httpClient.post(END_POINT + BANK_ACCOUNTS, { bank_account });
const updateBankAccount = (id, bank_account) => httpClient.put(END_POINT + BANK_ACCOUNTS + '/' + id, { bank_account } );


export {
    updateSettings,
    getSettings,
    getSetting,
    getBankAccounts,
    getBankAccount,
    deleteBankAccount,
    createBankAccount,
    updateBankAccount
}
