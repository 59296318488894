import Vue from 'vue'
// Notification system
import VueAWN from "vue-awesome-notifications"
import 'vue-awesome-notifications/dist/styles/style.css';

// Your custom options
Vue.use(VueAWN, {
  icons: {
    prefix: '<i style="font-size: 30px;" class="icon-',
    suffix: '"></i>',
    success: 'check-circle',
    warning: 'alert-circle',
    alert: 'alert-triangle'
  }
})
