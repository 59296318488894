const confirmationModal = {
    data(){
        return{
            confirm : false
        }
    },
    methods: {
        async confirmModal(message = "Are you sure?", okTitle = "DELETE", cancelTitle = "CANCEL", okVariant = 'danger'){
            try{
                const confirm = await this.$bvModal.msgBoxConfirm(message, {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: okVariant,
                    okTitle: okTitle,
                    cancelTitle: cancelTitle,
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    })
                if (confirm === true){
                    return true
                }else{
                    return false
                }
            } catch {
                console.log("error confirmation modal")
            }        
        }
    }
}

export default confirmationModal