import Vue from 'vue'
import Vuex from 'vuex'
import { refreshToken } from "@/api/auth.api"
import httpClient from '@/api/httpClient';

Vue.use(Vuex)
const TOKEN = 'token';
// Track the timeout so we can kill it in the logout method
var refreshTimeout = null;
const processToken = function(token, commit) {
    localStorage.setItem(TOKEN, token)
    httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + token
    // atob is base64 decode
    // the token has 3 parts, the 2nd part has the info
    // and the parts are separated by a '.'
    const refreshInfo = JSON.parse(atob(token.split(".")[1]))
    // Refresh the token 1 minute before it expires
    refreshTimeout = setTimeout(() => refreshFunc(commit), (refreshInfo.exp - refreshInfo.iat - 60) * 1000)
    // commit('auth_success', token)
}
const refreshFunc = function(commit) {
    refreshToken().then(response => {
        processToken(response.data.token, commit)
    })
}

const isExpired = function(token) {
    const info = JSON.parse(atob(token.split(".")[1]))
    return Date.now() > info.exp * 1000
}

export default new Vuex.Store({
  state: {
    token: localStorage.getItem(TOKEN) || '',
    logoURL: null
  },
  mutations: {
    auth_success(state, token){
      state.token = token
    },
    logout(state){
      state.token = ''
    },
    set_logoURL(state, url) {
        state.logoURL = url
    }
  },
  actions: {
    setToken({commit}, token) {
        processToken(token, commit)
    },
    logout({commit}){
      return new Promise((resolve) => {
        clearTimeout(refreshTimeout)
        localStorage.removeItem(TOKEN)
        delete httpClient.defaults.headers.common['Authorization']
        commit('logout')
        resolve()
      })
    },
    refreshLogoURL({commit}){
        commit('set_logoURL', httpClient.defaults.baseURL + '/settings/logo?t=' + Date.now())
    },
    validate({commit, dispatch}){
      return new Promise((resolve) => {
        var token = localStorage.getItem(TOKEN)
        if(token != null) {
            const refreshInfo = JSON.parse(atob(token.split(".")[1]))
            if(Date.now() < refreshInfo.exp * 1000) {
                // Refresh the token now
                httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + token
                refreshFunc(commit)
            } else {
                // Token is expired, remove it
                dispatch('logout')
            }
        }
        resolve()
      })
    }
  },
  getters : {
    isLoggedIn: state => () => !!state.token,
    isExpired: state => () => isExpired(state.token),
    token: state => state.token,
    logoURL: state => state.logoURL,
    userRole: state => {
        if(state.token) {
            return JSON.parse(atob(state.token.split(".")[1])).data.role
        }
        return ''
    },
    username: state => {
        if(state.token) {
            return JSON.parse(atob(state.token.split(".")[1])).data.username
        }
        return ''
    },
    user_id: state => {
        if(state.token) {
            return JSON.parse(atob(state.token.split(".")[1])).data.id
        }
        return ''
    }
  }
})

