import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue'),
      meta: {
        requiresAuth: true
      }
    }, {
      path: '/timesheet',
      name: 'Timesheet',
      component: () => import('../views/Timesheet.vue'),
      meta: {
        requiresAuth: true
      }
    }, {
      path: '/login',
      name: 'Login',
      props: true,
      component: () => import('../views/Login.vue')
    }, {
      path: '/profile',
      name: 'Profile',
      props: true,
      component: () => import('../views/Profile.vue'),
      meta: {
        requiresAuth: true
      }
    }, {
      path: '/users/:id',
      name: 'User',
      props: true,
      component: () => import('../views/User.vue'),
      meta: {
        requiresAuth: true,
        restrictedTo: ['admin']
      }
    }, {
      path: '/users',
      name: 'Users',
      component: () => import('../views/Users.vue'),
      meta: {
        requiresAuth: true,
        restrictedTo: ['admin']
      }
    },  {
      path: '/clients/:id',
      name: 'Client',
      props: true,
      component: () => import('../views/Client.vue'),
      meta: {
        requiresAuth: true,
        restrictedTo: ['admin']
      }
    }, {
      path: '/clients',
      name: 'Clients',
      component: () => import('../views/Clients.vue'),
      meta: {
        requiresAuth: true,
        restrictedTo: ['admin']
      }
    }, {
      path: '/quotes/:id',
      name: 'Quote',
      props: true,
      component: () => import('../views/Quote.vue'),
      meta: {
        requiresAuth: true,
        restrictedTo: ['admin']
      }
    }, {
      path: '/quotes',
      name: 'Quotes',
      component: () => import('../views/Quotes.vue'),
      meta: {
        requiresAuth: true,
        restrictedTo: ['admin']
      }
    }, {
      path: '/invoices/:id',
      name: 'Invoice',
      props: true,
      component: () => import('../views/Invoice.vue'),
      meta: {
        requiresAuth: true,
        restrictedTo: ['admin']
      }
    }, {
      path: '/invoices',
      name: 'Invoices',
      component: () => import('../views/Invoices.vue'),
      meta: {
        requiresAuth: true,
        restrictedTo: ['admin']
      }
    }, {
      path: '/reports',
      name: 'Reports',
      component: () => import('../views/Reports.vue'),
      meta: {
        requiresAuth: true,
        restrictedTo: ['admin']
      }
    }, {
      path: '/projects/:id',
      name: 'Project',
      component: () => import('../views/Project.vue'),
      meta: {
        requiresAuth: true,
        restrictedTo: ['admin']
      }
    }, {
      path: '/projects',
      name: 'Projects',
      component: () => import('../views/Projects.vue'),
      meta: {
        requiresAuth: true,
        restrictedTo: ['admin']
      }
    }, {
      path: '/settings',
      name: 'Settings',
      component: () => import('../views/Settings.vue'),
      meta: {
        requiresAuth: true,
        restrictedTo: ['admin']
      }
    }, {
      path: '/logs',
      name: 'Logs',
      component: () => import('../views/Logs.vue'),
      meta: {
        requiresAuth: true,
        restrictedTo: ['admin']
      }
    }, {
      path: '/taxes',
      name: 'Taxes',
      component: () => import('../views/Taxes.vue'),
      meta: {
        requiresAuth: true,
        restrictedTo: ['admin']
      }
    },
  ]
})

// Force login on pages requiring auth
router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.isLoggedIn()) {
            // If the user is visiting a restricted resource and isn't logged in
            next('/login');
            return
        } else if(store.getters.isExpired()) {
            Vue.prototype.$awn.alert('You have been logged out due to inactivity');
            store.dispatch('logout')
            return
        }
    }
    if(to.matched.some(record => record.name == 'Login')) {
        if(store.getters.isLoggedIn()) {
            // If the user is already logged in and tries to visit
            // the login page, send them Home
            next('/');
            return
        }
    }
    if(to.matched.some(
        record => record.meta.restrictedTo && !record.meta.restrictedTo.includes(store.getters.userRole)
        )) {
        // The users role is not allowed to visit this page, redirect to home
        next('/');
        return
    }
    next();
})

export default router
