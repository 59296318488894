import axios from 'axios';

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL || '/api',
	timeout: 25000
//    headers: {
//        "Content-Type": "application/json",
        // anything you want to add to the headers
//    }
});
httpClient.interceptors.response.use(undefined, err => {
    let response = err.response
    return new Promise((resolve, reject) => {
        if (response !== undefined) {
            if (response.status === 401) {
                if (response.config.url === '/auth/get-token') {
                    reject(err)
                } else {
                    // if you ever get an unauthorized, logout the user
                    this.$store.dispatch('logout')
                    this.$router.push({
                        name: 'Login',
                        params: { auth_error: 'Authentication error. Please re-authenticate'}
                    })
                    // you can also redirect to /login if needed !
                }
            }
        }
        reject(err)
    });
});
// Get the token if it exists
//const getAuthToken = () => localStorage.getItem('token');

//const authInterceptor = (config) => {
//    console.log(config.headers['Authorization'])
//    config.headers['Authorization'] = 'Bearer ' + getAuthToken();
//    console.log(config.headers['Authorization'])
//    return config;
//}

//httpClient.interceptors.request.use(authInterceptor);

export default httpClient;

