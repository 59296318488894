<template>
<div class="container">
<b-overlay :show="login_overlay" rounded="sm">

        <div class="row justify-content-md-center">
            <div class="col-lg-6 col-md-6">
                <div class="login-screen">
                    <div class="login-box" @keyup.enter="onSubmit">
                        <a href="#" class="login-logo w-100">
                            <img :src="$store.getters.logoURL" class="mx-auto d-block" alt="iNSiTU" />
                        </a>
						<br/>
						<h6>{{ welcome_msg }}</h6>
						<br/>
						<b-alert variant="danger" v-if="auth_error" show>{{ auth_error }}</b-alert>
						<div class="form-group">
                            <input type="text" v-model="username" class="form-control" placeholder="Username" />
						</div>
						<div class="form-group">
                            <input type="password" v-model="password" class="form-control" placeholder="Password" />
						</div>
						<div class="actions">
                            <button type="submit" class="btn btn-success" @click="onSubmit">Login</button>
						</div>
						<h6 class="text-right text-muted">v {{ version }}</h6>
					</div>
                </div>
            </div>
        </div>
</b-overlay>
	<b-modal ref="mfa-modal" hide-footer id="MFA" centered>
            <b-row>
                <b-col cols="12">
                    <b-icon icon="shield-lock-fill" variant="primary" style="width:120px; height:120px;"></b-icon>
                </b-col>
                <b-col cols="12">
                    <h4>Two-Factor Authentication</h4>
                </b-col>
                <b-col cols="12">
                    <b-form-group 
                    id="mfaLabel"
                    label="Enter your Two-Factor Authentication Code"
                    label-for="mfaInput">
                        <b-form-input placeholder="6-digit code" type="text" v-model="mfa" @keyup.enter="submitMfa" autofocus id="mfaInput"></b-form-input>
                    </b-form-group>
                    
                </b-col>
                <b-col cols="12">
                    <b-button :disabled="mfa.length <= 5" class="mt-3" variant="outline-danger" block @click="submitMfa">Submit</b-button>
                </b-col>
            </b-row>

    </b-modal>
</div>
</template>

<script>

import { getSetting } from '@/api/settings.api'
import crudMixin from '@/api/crud'
import { login } from '@/api/auth.api'

export default {
    mixins: [crudMixin],
    props: {
        auth_error: null
    },
    data() {
        return {
            username : '',
            password : '',
            mfa: '',
            challenge: null,
            welcome_msg: "Welcome back. Please Login to your Account.",
            version: process.env.VUE_APP_VERSION,
            login_overlay: false
        }
    },
    mounted() {
        this.call(
            getSetting('welcome_msg'),
            (res) => {
                if(res.data.welcome_msg.value) {
                    this.welcome_msg = res.data.welcome_msg.value
                }
            }
        )
    },
    methods: {
        hideModal() {
            this.$refs['mfa-modal'].hide()
        },
        submitMfa() {
            this.login_overlay = true
            this.hideModal()
            this.call(
                login({
                    'username': this.username,
                    'challenge_response': this.mfa,
                    'challenge': this.challenge
                }),
                (res) => {
                    if('token' in res.data) {
                        this.$store.dispatch('setToken', res.data.token)
                        location.reload();
                    } else {
                        this.$awn.alert(res.data.error);
                    }
                    this.login_overlay = false
                },
                (err) => {
                    this.$awn.alert(err);
                    this.login_overlay = false
                }
            )
            this.challenge = ''
        },
        onSubmit() {
            this.login_overlay = true
            this.call(
                login({
                    'username': this.username,
                    'password': this.password
                }),
                (res) => {
                    if('challenge' in res.data) {
                        this.challenge = res.data.challenge
                        this.$refs['mfa-modal'].show()
                    } else if('token' in res.data) {
                        this.$store.dispatch('setToken', res.data.token)
                        location.reload();
                    } else {
                        this.$awn.alert(res.data.error);
                    }
                    this.login_overlay=false
                },
                (err) => {
                    this.$awn.alert(err);
                    this.login_overlay=false
                }
            )
//            this.$store.dispatch('login', {this.username, this.password}).then(() =>{
//                location.reload();
//            }).catch(err => {
//                if(err.response.status == 401) {
//                    this.auth_error = 'Invalid username or password'
//                } else {
//                    this.$awn.alert(err);
//                }
//            })
        }
    },
    components: {
    }
}
</script>
<style>
    .modal-body{
        text-align: center;
    }
</style>
