<template>
  <b-navbar>
    <b-navbar-brand href="#" class="mr-auto" @click="onClick">
      <b-icon icon="justify" class="p-1 border rounded" font-scale="2"/>
    </b-navbar-brand>
    <b-navbar-nav class="ml-auto">

        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em>Settings</em>
          </template>
          <b-dropdown-item href="#/profile">Profile</b-dropdown-item>
          <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>

      </b-navbar-nav>
  </b-navbar>
</template>
<script>
import confirmationModal from '@/api/confirmation'

export default {
    mixins:[confirmationModal],
    name: 'Header',
    data() {
      return {}
    },
    methods: {
      logout() {
        this.confirmModal("Are you sure?", "SIGN OUT", "CANCEL").then((result) => {
          if (result){
            this.$store.dispatch('logout')
            .then(() => this.$router.push('/login'))
            .catch(err => this.$awn.alert(err))
          }
        })
       
      },
      onClick() {
        this.$root.$emit('sidebarToggle')
      }
    }
  }
</script>
<style>
  .logo[data-v-7d622f5c]{
    margin-left: 2px !important;
  }
</style>
