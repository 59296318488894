<template>

<div id="app">

    <Login v-if="!isLoggedIn" />

    <!-- Portal wrapper start -->
    <!-- Note: class="page-wrapper pinned" to retract menu by default -->
    <div class="page-wrapper" v-if="isLoggedIn" >
      <Sidebar />

      <div class="page-content m-3" id="mainContent">
        <Header />

        <div class="main-container">
          <router-view />
        </div>
      </div>
    </div>
    <!-- Portal wrapper end -->

  </div>
</template>

<script>
  import Header from './components/Header';
  import Sidebar from './components/Sidebar';
  import Login from './views/Login'
  import store from './store'

  export default {
    store,
    name: 'app',
    data() {
      return {
        image: null,

      }
    },
    components: {
      Header,
      Sidebar,
      Login
    },
    mounted() {
      this.$store.dispatch('refreshLogoURL')
      this.$root.$on('sidebarToggle', () => {
        this.onRezise()
      })
    },
    computed : {
      isLoggedIn() { return this.$store.getters.isLoggedIn(); },
      logo() { return this.$store.getters.logoURL; }
    },
    methods: {
      onRezise(){
        var mainContent = document.getElementById('mainContent')
        mainContent.classList.toggle('mainClose')
      }
    }
  }
</script>
<style>
body {
    font-weight: 400;
    font-size: 0.875rem;
    font-family: Open Sans, sans-serif;
    font-size-adjust: none;
}
.error {
    color: var(--danger);
}
#mainContent{
  padding-left: 250px;
}
#mainContent.mainClose{
  padding-left: 60px !important;
}
</style>
