// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import './assets/styles/bms.scss'
import 'vue-cal/dist/vuecal.css'
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import '@/plugins/bootstrap'
import '@/plugins/apexcharts'
import '@/plugins/vuecal'
import '@/plugins/awesome-notifications'
import DateRangePicker from "vue2-daterange-picker";


Vue.config.productionTip = false

store.dispatch('validate')
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App, DateRangePicker },
  template: '<App/>'
})
